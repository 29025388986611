(function () {
  const slider = $('.js-mitarbeiterprofile-slider');
  // Set the number of dots, dot size and margin here.
  const maxDots = 5;

  // Variables used to control the sliding animation
  let transformXIntervalNext;
  let transformXIntervalPrev;

  // Keep track of the current X (left/right scroll) position
  let transformCount = 0;

  function setBoundaries($slick, windowSize) {
    let $dots = $slick.$dots.find('li');
    if (!$dots.length) return;
    if (windowSize < $dots.length) {
      $slick.$dots
        .find('.slick-active')
        .nextAll()
        .eq(windowSize - 2)
        .addClass('small');
    }
    let $dot = $dots.first();
    let marginLeft = Math.round(parseFloat($dot.css('margin-left')));
    let marginRight = Math.round(parseFloat($dot.css('margin-right')));
    let dotWidth = 15 + marginLeft + marginRight;
    let viewportWidth = dotWidth * (windowSize - 1) + 31 + 10;

    // Calculate the viewport width
    $('.slick-dots-viewport').css('width', viewportWidth + 'px');

    // Define the left/right increments to smoothly scroll the dots.
    transformXIntervalPrev = dotWidth;
    transformXIntervalNext = -dotWidth;
  }

  slider.on('init breakpoint', function (event, slick) {
    // Since the dots location is customisable, use the config options to find them.
    const dots = slick.$dots;
    if (!dots) return;
    // Add an index to each dot so we can easily identify them.
    dots.find('li').each(function (index) {
      $(this).addClass('dot-index-' + index);
    });

    const activeDot = dots.find('.slick-active');

    let activeOffset = activeDot.position().left;
    const maxOffset = (dots.find('li').length - 5) * 25; //25 size of dot with margin;

    dots.removeClass('small');
    if (activeOffset > maxOffset) {
      dots.find('li').eq(-5).addClass('small');
      activeOffset = maxOffset;
    } else if (activeOffset > 0) {
      activeDot.prev().addClass('small');
      activeDot.nextAll().eq(2).addClass('small');
      activeOffset = activeOffset - 25;
    }

    transformCount = -activeOffset;
    // Reset the list position inside the viewport window
    //dots.css('transform', `translateX(${-activeOffset}px)`);

    // Resize the viewport and initialise the overflow dot (if necessary)
    setBoundaries(slick, maxDots);
  });

  slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    if (!slick.$dots) return;
    let $dotList = slick.$dots;
    let $dots = $dotList.find('li');
    let $firstDot = $dots.first();
    let $lastDot = $dots.last();
    let totalCount = $dots.length;

    if (totalCount > maxDots) {
      let delta = nextSlide - currentSlide;
      if (Math.abs(delta) === totalCount - 1) {
        // Reset the style of every dot because we're about to wrap around
        $dots.removeClass('small');
        let boundaryDot;
        if (delta < 0) {
          // Wrapping around to the start
          transformCount = 0;
          boundaryDot = maxDots - 1;
        } else {
          // Wrapping around to the end
          transformCount = (totalCount - maxDots) * transformXIntervalNext;
          boundaryDot = totalCount - maxDots;
        }
        $dots.filter('.dot-index-' + boundaryDot).addClass('small');

        // Animate the dots into position
        $dotList.css('transform', 'translateX(' + transformCount + 'px)');
      } else {
        let $nextSlide = $dots.filter('.dot-index-' + nextSlide);
        if (nextSlide > currentSlide) {
          if ($nextSlide.hasClass('small')) {
            // We haven't reached the end of the list yet, scroll the dots to the left.
            transformCount = transformCount + transformXIntervalNext;

            // Remove the existing right-side boundary dot...
            $nextSlide.removeClass('small');

            // ...and move it one place to the right UNLESS we already reached the last dot.
            if (!$nextSlide.next().is($lastDot)) {
              $nextSlide.next().addClass('small');
            }

            // Smoothly slide the dots to the left
            $dotList.css('transform', 'translateX(' + transformCount + 'px)');

            // Update the left-side boundary dot.
            let $firstVisibleDot = $dots.eq(nextSlide - (maxDots - 2));
            $firstVisibleDot
              .addClass('small')
              .prev()
              .removeClass('small')
              .addClass('tiny');
          }
        } else {
          // If the previous button has the "small" dot style...
          if ($nextSlide.hasClass('small')) {
            // We haven't reached the start of the list yet, scroll the dots to the right.
            transformCount = transformCount + transformXIntervalPrev;

            // Remove the existing left-side boundary dot...
            $nextSlide.removeClass('small');

            // ...and move it one place to the left UNLESS we already reached the first dot.
            if (!$nextSlide.prev().is($firstDot)) {
              $nextSlide.prev().addClass('small');
            }

            // Smoothly slide the dots to the left
            $dotList.css('transform', 'translateX(' + transformCount + 'px)');

            // Update the left-side boundary dot.
            let $lastVisibleDot = $dots.eq(nextSlide + (maxDots - 2));
            $lastVisibleDot
              .addClass('small')
              .next()
              .removeClass('small')
              .addClass('tiny');
          }
        }
      }
    }
  });

  slider.on('afterChange', function (e, slick) {
    if (!slick.$dots) return;
    let $slider = $(this);
    let $dotList = $slider.find('ul.slick-dots');
    let $dots = $dotList.find('li');
    $dots.filter('.tiny').removeClass('tiny');
  });

  const dotsWrapper = slider.next('.slick-dots-viewport');

  slider.slick({
    arrows: true,
    dots: false,
    appendDots: dotsWrapper,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slideToScroll: 1,
    rows: 0,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  });
})();
