import 'slick-carousel';
import Foundation from './inc/foundation';
import './inc/slick-custom-dots';
import './inc/functions';

$(document).foundation();

/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.header-main').toggleClass('menu-active');
    $('.menu-icon').toggleClass('is-active');
  })
  .on('changed.zf.mediaquery', function () {
    $('.header-main').removeClass('menu-active');
    $('.menu-icon').removeClass('is-active');
  });

/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});
$(window).on('load resize', () => {
  const header = $('.header');
  header.css('--header-height', header.outerHeight() + 'px');
  header.css(
    '--header-top-height',
    header.find('.header-top').outerHeight() + 'px'
  );
});
$('.menu-icon').on('click', function () {
  $body.toggleClass('scroll-fixed-popup');
  $('.header-top').toggleClass('hide-for-open-menu');
});

$(window).on('scroll', startCounter);

function startCounter() {
  $('.counter_block').each(function (i, section) {
    if ($(section).isInViewport()) {
      if ($(section).hasClass('js-active')) return;

      $(section).addClass('js-active');
      $(section)
        .find('.counter-item_number')
        .each(function (i, counter) {
          $(counter).countUp();
        });
    } else {
      $(section).removeClass('js-active');
    }
  });
}

startCounter();
$('.footer-menu__title').on('click', function () {
  $(this).next().toggleClass('open');
  $(this).parent().toggleClass('open');
  $('.footer-menu__title').not(this).next().removeClass('open');
  $('.footer-menu__title').not(this).parent().removeClass('open');
});

/* ---------------------------------------
	Quote Slider
--------------------------------------- */
$('.js-quote-slider').slick({
  arrow: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slideToScroll: 1,
  rows: 0,
  responsive: [
    {
      breakpoint: 1350,
      settings: {
        arrows: false,
      },
    },
  ],
});

/* ---------------------------------------
	Overlay
--------------------------------------- */
let $body = $('body');
let $overlayButtonClose = $('.js-overlay-button-close');

$('.js-button-overlay-personalanfragen').on('click', function (e) {
  e.preventDefault();
  $('.js-personalanfragen').addClass('show-overlay');
  $body.addClass('scroll-fixed-popup');
});

$overlayButtonClose.on('click', function () {
  closePopup();
});

function closePopup() {
  $('.js-personalanfragen').removeClass('show-overlay');
  $body.removeClass('scroll-fixed-popup');
  $('.js-overlay-tabs').removeClass('show-overlay');
}

$(window).click(function (event) {
  if ($(event.target).hasClass('show-overlay')) {
    closePopup();
  }
});

$('.js-button-overlay-tabs').on('click', function () {
  $('.js-overlay-tabs').addClass('show-overlay');
  $body.addClass('scroll-fixed-popup');
});

$('.form-tabs__tab-title').on('click', function () {
  let sectionID = $(this).find('a').attr('href').replace(/#/, '');
  $(this).closest('.form-tabs').attr('data-tab', sectionID);
});

/* ---------------------------------------
	Overlay Tab
--------------------------------------- */
let $overlayTabPanel = $('.overlay-tab-panel');
$('.overlay-tabs-item li').on('click', function () {
  $overlayTabPanel.removeClass('active');
  var index = $(this).index();
  $overlayTabPanel.eq(index).addClass('active');
});

//Please don't remove this snippet before going to live

$('input[type="file"]').on('change', function () {
  // console.log(this.files);
  const that = this;
  const file = this.files[0];
  const label = $(this).closest('.gfield').find('.gfield_label');
  label.text(file.name);
  $(this).one('click', function (e) {
    e.preventDefault();
    that.value = '';
    label.text('No File');
    console.log(that.files);
  });
});

function initSliderOnMobile(
  selector,
  options = {},
  breakpoint = 'medium',
  setArrows = false
) {
  $(selector).each(function (idx, item) {
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = $(item).hasClass('slick-initialized');
      if (Foundation.MediaQuery.is(breakpoint)) {
        if (isSlider) {
          $(item).slick('unslick');
        }
      } else if (!isSlider) {
        if (setArrows) {
          $.extend(options, {
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
          });
        }
        $(item).slick(options);
      }
    });
  });
}

let teamMembers = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  variableWidth: true,
};

// eslint-disable-next-line prettier/prettier
initSliderOnMobile('.team_members', teamMembers, 'large', true);

$('button.show-all-questions').on('click', function () {
  const textToShow = $(this).data('text-show');
  const textToHide = $(this).data('text-hide');
  $(this)
    .toggleClass(function () {
      if ($(this).hasClass('open')) {
        $(this).html(textToShow);
      } else {
        $(this).html(textToHide);
      }

      return 'open';
    })
    .parents('.accordion_items')
    .find('.accordion-item:nth-child(n + 6)')
    .toggleClass('hidden');
});

let scrollTopButton = $('.scrolltop');

scrollTopButton.on('click', function () {
  $('html, body').animate(
    {
      scrollTop: 0,
    },
    800
  );
});

/* ---------------------------------------
	Scroll
--------------------------------------- */
$('a[href*="#"]').on('click', function (e) {
  const $this = $(this);
  if (
    $this.closest('[data-tabs]').length ||
    $this.closest('[data-accordion]').length
  )
    return;

  const href = $this.attr('href');
  if (href.indexOf('#') === 0) {
    e.preventDefault();
    scrollToBlock(href);
  } else {
    let split = href.split('#');
    let location = window.location.href.replace('#', '');
    if (location === split[0]) {
      e.preventDefault();
      scrollToBlock('#' + split[1]);
    }
  }
});

//Smooth scroll to another page
let locationHash = window.location.hash;
if (locationHash) {
  window.location.hash = '';
  scrollToBlock(locationHash);
}

function scrollToBlock(id) {
  const block = $(id);
  if (!block.length) return;
  const headerHeight = $('.header .sticky-container').height();
  let offset = headerHeight;

  $('html,body').animate(
    {
      scrollTop: $(id).offset().top - offset,
    },
    400
  );
}

$(window).on('load changed.zf.mediaquery resize', function () {
  if (Foundation.MediaQuery.is('small only')) {
    $('.mitarbeiterprofile-posts-category__image-thumbnail img').on(
      'click',
      function () {
        $('.mitarbeiterprofile-posts-category__featured-image').removeClass(
          'show'
        );
        $(this)
          .closest('.mitarbeiterprofile-posts-category__featured-image')
          .addClass('show');
      }
    );

    $('body, html').on('click', function (e) {
      if (
        !$(e.target).closest(
          '.mitarbeiterprofile-posts-category__image-thumbnail'
        ).length
      ) {
        $(
          '.mitarbeiterprofile-posts-category__image-thumbnail img'
        ).removeClass('show');
        $('.mitarbeiterprofile-posts-category__featured-image').removeClass(
          'show'
        );
      }
    });
  }
});

function stylingUploadInputGravityForm() {
  function rewriteUploadsDescription() {
    const inputParent = $('.gfield--type-fileupload');
    if (inputParent.length === 0) return;
    inputParent.each(function (_, item) {
      const descriptionSpanParent = $(item).find(
        '.ginput_container_fileupload'
      );
      const descriptionSpan = $(item).find('.gform_fileupload_rules');
      const descriptionText = descriptionSpan.text();
      const html = `<p class="ruls_description">${descriptionText
        .substring(descriptionText.lastIndexOf(',') + 1)
        .trim()}</p>`;
      descriptionSpanParent.append(html);
    });
  }

  if ($('.gform-body').length === 0) return;
  rewriteUploadsDescription();

  // eslint-disable-next-line no-undef
  gform.addAction('gform_input_change', function (elem, formId, fieldId) {
    if (elem.type !== 'file') return false;
    let inputParent = $(`#field_${formId}_${fieldId}`);
    let maxFileSizeCanBeeLoad = Math.floor(
      Number(inputParent.find('input[type="hidden"]').val()) / 1000
    );
    let inputFile = inputParent.find('input[type="file"]');
    let fileName = inputFile[0].files[0].name;
    let fileSize = Math.floor(Number(inputFile[0].files[0].size) / 1000);

    addError();
    changeLabelName();

    function addError() {
      if (!checkFile()) {
        inputParent.addClass('error');
      } else {
        inputParent.removeClass('error');
      }
    }

    function changeLabelName() {
      let label = inputParent.find('.gfield_label');
      let labelFirstText = '';

      if (checkFile()) {
        labelFirstText = label[0].innerText;
        inputParent.addClass('success');

        if (!label.attr('data-first-text')) {
          label.attr('data-first-text', labelFirstText);
        }

        const html = `
          <p class="file_name">${fileName}</p>
          <p class="file_size">${
            fileSize > 1000
              ? (fileSize / 1000).toFixed(2) + ' Mb'
              : fileSize + 'KB'
          }</p>
        `;
        label.html(html);
      }
    }

    function checkFile() {
      let fileFormats = []; //takes all formats who can use
      let fileFormat = fileName.split('.').pop();
      takeFileFormats(fileFormats);

      return (
        fileFormats.includes(fileFormat) && maxFileSizeCanBeeLoad > fileSize
      );
    }

    function takeFileFormats(arr) {
      const inputString = inputParent.find('.gform_fileupload_rules')[0]
        .innerText;
      const words = inputString.split(' ');
      $.each(words, function (index, word) {
        if (word.endsWith(',')) {
          arr.push(word.replace(',', ''));
        }
      });
    }
  });

  function clearInputFile() {
    $(document).on('click', '.gfield--type-fileupload', function (e) {
      const parent = $(this);
      if (parent.hasClass('success')) {
        e.preventDefault();
        const inputFile = parent.find('input[type="file"]');
        const label = parent.find('label');
        inputFile.val('');
        parent.removeClass('success');
        label.text(label.attr('data-first-text'));
      }
    });
  }
  clearInputFile();

  //If validation error ================
  $(document).on('gform_post_render', function (_, formID) {
    if ($('.gform_validation_error').length === 0) return;

    const form = $(`#gform_${formID}`);
    const inputFile = form.find('input[type="file"]');
    if (inputFile.length === 0) return;

    inputFile.each(function (_, item) {
      const inputParent = $(item).closest('.gfield--type-fileupload');
      const inputPreview = inputParent.find(
        '.ginput_preview .gfield_fileupload_filename'
      );

      if (inputPreview.length === 0) return;

      const inputLabel = inputParent.find('.gfield_label');
      const labelFirstText = inputLabel[0].innerText;
      const inputPreviewText = inputPreview.text();

      inputLabel.attr('data-first-text', labelFirstText);
      inputLabel.text(inputPreviewText);
    });
  });
}
stylingUploadInputGravityForm();

/* ---------------------------------------
	Form Custom Select
--------------------------------------- */
$('#psj-anrede-uuid').each(function () {
  var $this = $(this),
    selectOptions = $(this).children('option').length;

  if ($this.hasClass('hide-select')) return;
  $this.addClass('hide-select');
  $this.wrap('<div class="select"></div>');
  $this.after('<div class="custom-select"></div>');

  var $customSelect = $this.next('div.custom-select');
  $customSelect.text($this.children('option').eq(0).text());

  var $optionlist = $('<ul />', {
    class: 'select-options',
  }).insertAfter($customSelect);

  for (var i = 0; i < selectOptions; i++) {
    $('<li />', {
      text: $this.children('option').eq(i).text(),
      rel: $this.children('option').eq(i).val(),
    }).appendTo($optionlist);
  }

  var $optionlistItems = $optionlist.children('li');

  $customSelect.click(function (e) {
    e.stopPropagation();
    $('div.custom-select.active')
      .not(this)
      .each(function () {
        $(this).removeClass('active').next('ul.select-options').hide();
      });
    $(this).toggleClass('active').next('ul.select-options').slideToggle();
  });

  $optionlistItems.click(function (e) {
    e.stopPropagation();
    $customSelect.text($(this).text()).removeClass('active');
    $this.val($(this).attr('rel'));
    $optionlist.hide();
  });

  $(document).click(function () {
    $customSelect.removeClass('active');
    $optionlist.hide();
  });

  $(document).ready(function () {
    $customSelect.removeClass('active');
    $optionlist.hide();
  });

  $('.psj-send-form-button').click(function () {
    checkError();
  });

  function checkError() {
    var selectField = $this[0];
    var errorHint = $this.closest('.select').find('.custom-select');

    if (selectField.value === '') {
      errorHint.addClass('psj-input-error');
    } else {
      errorHint.removeClass('psj-input-error');
    }
  }
});

$(document).ready(function () {
  if ($('.hero-stellendetails #psj-image-container').length) {
    $('header').addClass('header-white');
    $('.hero-stellendetails__content').addClass('hero-content-white');
  }
});

$('.language-switcher__button').on('click', function () {
  $(this).next('.language-switcher__list').slideToggle();
});
